import {
  ContentfulQueryKeys,
  ContentfulRequest,
  contentful,
} from '@oresundsbron/api';
import { FallbackPageInformation } from '@oresundsbron/api/src/types/contentful/Library/FallbackPageInformation';
import { unwrap } from '@oresundsbron/utilities';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { contentfulReq } from '~/lib/api/server';

export function useFallbackPageInformation() {
  const { locale } = useRouter();
  const getData = async () => {
    const request = await unwrap<ReturnType<() => ContentfulRequest>>(
      contentfulReq
    );
    const response = unwrap<FallbackPageInformation>(
      contentful.FallbackPageInformation.get({ locale })(request)
    );

    return response;
  };

  const { data, error, isLoading } = useQuery(
    ContentfulQueryKeys.FallbackPageInformation({}),
    getData
  );

  return { data, error, isLoading };
}
