import { Metadata } from '@oresundsbron/api';
import { MetadataItem } from './metadata';
import { ogImage } from '@oresundsbron/validators/src/constants';

export const addFallbackMetaItems = (
  fallbackMetaData?: Metadata[],
  pageMetaData?: MetadataItem[]
): MetadataItem[] => {
  if (!fallbackMetaData || !pageMetaData) return pageMetaData || [];

  let mergedMetaData = [...pageMetaData];

  const fallbackCodeIds = fallbackMetaData.map(
    (metadata) => metadata.name.codeId
  );
  const pageMetaDataIds = pageMetaData.map((metadata) => metadata.name);

  fallbackCodeIds.forEach((fallbackCodeId, fallbackCodeIndex) => {
    const index = pageMetaDataIds.indexOf(fallbackCodeId);

    if (index === -1) {
      const foundFallbackMetaData = fallbackMetaData[fallbackCodeIndex];
      const content =
        (fallbackCodeId === ogImage
          ? foundFallbackMetaData.media?.url
          : foundFallbackMetaData.content) || '';
      const newMetaData = { name: foundFallbackMetaData.name.codeId, content };
      mergedMetaData.push(newMetaData);
    }
  });

  return mergedMetaData;
};
