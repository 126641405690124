import { Page } from '@oresundsbron/api';

export const mainImageFromPage = (page: Page | undefined) => {
  if (!page) return;

  const { banner, content } = page;

  if (banner) return banner;

  return content?.flatMap(
    (content) =>
      content.columns?.flatMap(
        (column) => column.content?.links?.assets?.block || column.media || []
      ) || []
  )[0];
};
