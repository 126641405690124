import Head from 'next/head';
import { useRouter } from 'next/router';
import { FC } from 'react';
import useLocaleCode from '../hooks/useLocaleCode';
import { MetadataItem } from '../lib/metadata';
import { FAQPage, WithContext } from 'schema-dts';
import { customJsonLd } from '@oresundsbron/api/src/types/contentful/CustomJsonLd';
import { useFallbackPageInformation } from '~/hooks/useFallbackPageInformation';
import { addFallbackMetaItems } from '~/lib/addFallbackMetaItems';

type SEOMetadataProps = {
  items: MetadataItem[];
  predefinedJsonLd?: WithContext<FAQPage>;
  customJsonLd?: customJsonLd;
};

export const SEOMetadata: FC<SEOMetadataProps> = ({
  items = [],
  predefinedJsonLd,
  customJsonLd,
}) => {
  const { locale, ...restProps } = useRouter();
  const localeCode = useLocaleCode(locale || '');

  const { data } = useFallbackPageInformation();

  const updatedItems = addFallbackMetaItems(data?.metadata, items);

  const noIndex = updatedItems.map((item) => item.content).includes('noindex');

  const envUrl = process.env.NEXT_BASE_URL;
  const lastURLIndex = envUrl?.lastIndexOf('/');

  const url =
    lastURLIndex && envUrl && lastURLIndex === envUrl?.length - 1
      ? envUrl?.substring(0, lastURLIndex)
      : envUrl;

  const baseUrl = `${url}/${locale}`;

  const typeSwitch = (item: MetadataItem) => {
    if (!(item?.content?.length > 0)) return;
    const { name, content } = item;
    if (name.includes('seo-title')) {
      return 'og-title';
    }
    if (name.includes('seo') && content.length > 0) {
      return 'seo';
    } else if (name.includes('og')) {
      return 'og';
    }
  };

  const newMetaTags = updatedItems.map((item) => {
    const { name, content } = item;
    const type = typeSwitch(item);
    if (type) {
      switch (type) {
        case 'og-title':
          const ogTitleName = name.replace('seo-', 'og:');
          return (
            <meta key={ogTitleName} property={ogTitleName} content={content} />
          );
        case 'seo':
          const ogTitleName2 = name.replace('seo-', 'og:');
          return [
            <meta
              key={name}
              name={name.replace('seo-', '')}
              content={content}
            />,
            <meta
              key={ogTitleName2}
              property={ogTitleName2}
              content={content}
            />,
          ];
        case 'og':
          return (
            <meta
              property={name}
              content={
                name.includes('og:url') ? `${baseUrl}${content}` : content
              }
              key={name}
            />
          );
        default:
          break;
      }
    }
  });

  const titleFromPageMetaData = updatedItems.find(
    (x) => x.name === 'seo-title'
  )?.content;

  const title = titleFromPageMetaData
    ? `${titleFromPageMetaData} - Øresundsbron`
    : 'Øresundsbron';

  const canonicalHref = updatedItems.find(
    (x) => x.name === 'canonical'
  )?.content;
  const canonical = (
    <link
      rel="canonical"
      href={`${
        canonicalHref
          ? canonicalHref
          : `${baseUrl}${restProps.asPath.split('?')[0]}`
      }`}
    />
  );

  return (
    <Head>
      <title>{title}</title>
      {noIndex ? (
        <meta name="robots" content="noindex" />
      ) : (
        <meta name="robots" content="index, follow" />
      )}
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <>{newMetaTags}</>
      <meta property="og:site_name" content="Øresundsbron" />
      <meta property="og:locale" content={localeCode} />
      {predefinedJsonLd && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(predefinedJsonLd) }}
        />
      )}
      {customJsonLd?.content && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(customJsonLd.content),
          }}
        />
      )}
      {canonical && canonical}
    </Head>
  );
};
