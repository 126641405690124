export const ogImage = 'og:image';
export const ogImageAlt = 'og:image:alt';
export const ogUrl = 'og:url';
export const ogType = 'og:type';

export const seoCanonicalHref = 'seo-canonicalHref';
export const seoDescription = 'seo-description';
export const seoTitle = 'seo-title';

export const metaKeys = [
  'seo-title',
  'seo-description',
  'seo-canonicalHref',
  'robots',
  'og:image',
  'og:image:alt',
  'og:locale',
  'og:type',
  'og:url',
  'canonical',
] as const;
