import { GridColumn } from '@oresundsbron/api';
import { cx } from 'class-variance-authority';
import { match } from 'fp-ts/lib/boolean';
import { flow, pipe } from 'fp-ts/lib/function';

import {
  alt,
  chain,
  chainFirst,
  fromNullable,
  fromPredicate,
  getOrElse,
  getOrElseW,
  map,
  of,
  toUndefined,
} from 'fp-ts/lib/Option';
import { lookup } from 'fp-ts/lib/Record';
import Image from 'next/image';
import { FC, useMemo } from 'react';
import { RichText } from '../../components/RichText';
import { CodeReference, codeRefExist } from './CodeReference';
import { Box } from '@oresundsbron/bridge-ui';
import { split } from 'fp-ts/lib/string';
import { toArray } from 'fp-ts/lib/ReadonlyArray';
import { head } from 'fp-ts/lib/Array';
import { strMatcher } from '~/lib/matchers';
import { AssetVideo } from '~/components/RichText/AssetVideo';
import { DefaultLink } from '~/components/Links/DefaultLink';

const colStart = {
  1: 'md:col-start-1',
  2: 'md:col-start-2',
  3: 'md:col-start-3',
  4: 'md:col-start-4',
};

const getColStart = (l: number) =>
  pipe(
    colStart,
    lookup(`${l}`),
    getOrElse(() => 'md:col-start-1')
  );

const textColor = {
  'inform.100': 'text-blue-100',
  'inform.200': 'text-blue-200',
  'inform.300': 'text-blue-600',
  'inform.500': 'text-blue-800',
  'negative.100': 'text-red-100',
  'negative.200': 'text-red-300',
  'negative.300': 'text-red-400',
  'negative.500': 'text-red-200',
  'negative.700': 'text-red-700',
  'neutral.100': 'text-white',
  'neutral.150': 'text-neutral-150',
  'neutral.200': 'text-neutral-200',
  'neutral.400': 'text-neutral-400',
  'neutral.500': 'text-neutral-500',
  'neutral.700': 'text-neutral-700',
  'neutral.900': 'text-neutral-900',
  'notice.100': 'text-yellow-100',
  'notice.200': 'text-yellow-200',
  'notice.300': 'text-yellow-300',
  'positive.100': 'text-green-100',
  'positive.200': 'text-green-200',
  'positive.300': 'text-neutral-400',
  'positive.600': 'text-green-800',
  'primary.50': 'text-primary-50',
  'primary.100': 'text-primary-100',
  'primary.200': 'text-primary-200',
  'primary.300': 'text-primary-300',
  'primary.400': 'text-primary-400',
  'primary.600': 'text-primary-600',
  'primary.800': 'text-primary-800',
  'primary.900': 'text-primary-900',
  'primary.950': 'text-primary-950',
};

const getTextColor = (str: string) =>
  pipe(
    textColor,
    lookup(str),
    getOrElseW(() => undefined)
  );

export const pageWrapperGrid = cx(
  'grid',
  'grid-cols-[minmax(1em,_1fr)_minmax(0,_44em)_minmax(1em,_1fr)]',
  'sm:grid-cols-[minmax(2em,_1fr)_minmax(0,_67.5em)_minmax(2em,_1fr)]',
  'md:grid-cols-[minmax(2em,_1fr)_minmax(0,_81em)_minmax(2em,_1fr)]'
);

export const leftHalfWrapper = cx(
  'grid',
  'grid-cols-[minmax(1em,_1fr)_minmax(0,_44em)_minmax(1em,_1fr)]',
  'sm:grid-cols-[minmax(2em,_1fr)_minmax(0,_67.5em)_minmax(2em,_1fr)]',
  'md:grid-cols-[minmax(2em,_1fr)_minmax(0,_36.5em)]',
  'md:pr-12'
);

export const rightHalfWrapper = cx(
  'grid',
  'grid-cols-[minmax(1em,_1fr)_minmax(0,_44em)_minmax(1em,_1fr)]',
  'sm:grid-cols-[minmax(2em,_1fr)_minmax(0,_67.5em)_minmax(2em,_1fr)]',
  'md:grid-cols-[minmax(0,_36.5em)_minmax(2em,_1fr)]',
  'md:pl-12'
);

export const pageGridMaxWidthWrapper = cx(
  'max-w-[44em]',
  'sm:max-w-[67.5em]',
  'md:max-w-[81em]'
);

export const Column: FC<
  GridColumn & { pos: number; total: number; bleed: boolean; height?: string }
> = ({
  codeRef,
  pos,
  content,
  fontColor,
  textAlignment,
  alignment,
  media,
  bleed,
  total,
  height,
}) => {
  const cont = useMemo(
    () =>
      pipe(
        codeRef,
        fromNullable,
        chainFirst(({ ref }) => codeRefExist(ref)),
        map(({ ref, ...rest }) => <CodeReference {...rest} code={ref} />),
        alt(() =>
          pipe(
            content,
            fromNullable,
            map((c) => (
              <RichText
                color={fontColor ? getTextColor(fontColor.value) : undefined}
                content={c}
              />
            ))
          )
        ),
        alt(() =>
          pipe(
            media,
            fromNullable,
            map((m) =>
              pipe(
                m.contentType,
                fromNullable,
                chain(flow(split('/'), toArray, head)),
                map((cType) =>
                  pipe(
                    cType as 'image' | 'application' | 'video',
                    strMatcher({
                      application: () => (
                        <DefaultLink href={m.url} target="_blank" underline>
                          {m.title ?? m.description}
                        </DefaultLink>
                      ),
                      image: () => (
                        <Box className="aspect-2/1 md:aspect-auto">
                          <Image
                            src={m.url}
                            alt={m.description || ''}
                            sizes="(max-width: 1200px) 100vw,
                            50vw"
                            fill
                            style={{
                              aspectRatio: '16/9',
                              objectFit: 'cover',
                            }}
                          />
                        </Box>
                      ),
                      video: () => <AssetVideo {...m} />,
                    })
                  )
                ),
                getOrElse(() => (
                  <DefaultLink href={m.url} target="_blank" underline>
                    {m.title ?? m.description}
                  </DefaultLink>
                ))
              )
            )
          )
        ),

        getOrElseW(() => null)
      ),
    [codeRef, content, media, fontColor]
  );

  const textAlign = useMemo(
    () =>
      pipe(
        textAlignment,
        fromPredicate((x) => x === 'Left'),
        map(() => 'text-left'),
        alt(() =>
          pipe(
            textAlignment,
            fromPredicate((x) => x === 'Center'),
            map(() => 'text-center')
          )
        ),
        alt(() =>
          pipe(
            textAlignment,
            fromPredicate((x) => x === 'Right'),
            map(() => 'text-right')
          )
        ),
        getOrElseW(() => undefined)
      ),
    [textAlignment]
  );

  const align = useMemo(
    () =>
      pipe(
        alignment,
        fromPredicate((x) => x === 'Top'),
        map(() => 'self-start'),
        alt(() =>
          pipe(
            alignment,
            fromPredicate((x) => x === 'Center'),
            map(() => 'self-center')
          )
        ),
        alt(() =>
          pipe(
            alignment,
            fromPredicate((x) => x === 'Bottom'),
            map(() => 'self-end')
          )
        ),
        getOrElseW(() => undefined)
      ),
    [alignment]
  );

  const wrapperStyling = useMemo(
    () =>
      bleed
        ? cx(
            pos === 0 && total > 1 ? leftHalfWrapper : undefined,
            pos + 1 === total && total > 1 ? rightHalfWrapper : undefined,
            total === 1 ? pageWrapperGrid : undefined
          )
        : 'flex',
    [bleed, pos, total]
  );

  const space = useMemo(
    () =>
      pipe(
        pos,
        fromPredicate((x) => x === 0),
        chain(() =>
          pipe(
            bleed,
            match(
              () => 'md:mr-16',
              () => 'md:pr-16'
            ),
            of
          )
        ),
        alt(() =>
          pipe(
            pos,
            fromPredicate((x) => x + 1 === total),
            chain(() =>
              pipe(
                bleed,
                match(
                  () => 'md:ml-16',
                  () => 'md:pl-16'
                ),
                of
              )
            )
          )
        ),
        alt(() => pipe('md:mx-8', of)),
        chain(fromPredicate(() => total > 1)),
        toUndefined
      ),
    [pos, bleed, total]
  );

  const childStyling = useMemo(
    () =>
      bleed
        ? cx(
            pos === 0 && total > 1 ? 'col-start-2' : undefined,
            pos + 1 === total && total > 1
              ? 'col-start-2 md:col-start-1'
              : undefined,
            total === 1 ? 'col-start-2' : undefined
          )
        : undefined,
    [bleed, pos, total]
  );

  if (!cont) {
    return null;
  }

  return (
    <Box
      className={cx(
        wrapperStyling,
        'relative',
        getColStart(pos + 1),
        'py-6',
        'md:py-12',
        space,
        textAlign,
        height,
        media ? 'row-start-1' : undefined
      )}
    >
      <Box className={cx(childStyling, align, 'w-full')}>{cont}</Box>
    </Box>
  );
};
